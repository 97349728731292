<template>
  <div class="imports-table">
    <v-simple-table>
      <thead>
        <tr class="imports-table__header">
          <th v-if="!forCurrentImports || wantsFileName">
            File Name
          </th>
          <th>User</th>
          <th>Import Type</th>
          <th>Created On</th>
          <th v-if="!forCurrentImports">
            Completed On
          </th>
          <th># Rows</th>
          <th>
            Import Status
          </th>
          <th>
            {{ forCurrentImports ? "Progress / Estimated Time Left" : "" }}
          </th>
        </tr>
      </thead>
      <tbody class="imports-table__content">
        <template v-if="formattedImports.length">
          <tr v-for="(item, i) in formattedImports" :key="i">
            <template v-if="item">
              <td v-if="!forCurrentImports || wantsFileName">
                {{ getPlaceholder(item.fileName) }}
              </td>
              <td>
                <div>{{ getPlaceholder(item.username) }}</div>
                <small v-if="item.email">{{ item.email }}</small>
              </td>
              <td>{{ getPlaceholder(item.importType) }}</td>
              <td>
                <div :title="item.createdAtFullDateTime">
                  {{ getPlaceholder(item.createdAt) }}
                </div>
              </td>
              <td v-if="!forCurrentImports">
                <div :title="item.updatedAtFullDateTime">
                  {{ getPlaceholder(item.updatedAt) }}
                </div>
              </td>
              <td>{{ getPlaceholder(item.totalRows) }}</td>
              <td>
                <span>
                  <template v-if="item.status === 'failed' && item.importData.failedException">
                    <v-tooltip left>
                      <template #activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          style="text-decoration: underline; color: #1976d2"
                          v-on="on"
                        >
                          {{ getPlaceholder(item.prettyStatus) }}
                        </span>
                      </template>
                      <span>{{ getPlaceholder(item.importData.failedException) }}</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    {{ getPlaceholder(item.prettyStatus) }}
                  </template>
                </span>
                <small v-if="item.importData.subStatus">
                  <br>
                  ({{ item.importData.subStatus }})
                </small>
              </td>
              <td v-if="!forCurrentImports">
                <template v-if="item.downloadOptions.length > 0">
                  <v-btn-toggle>
                    <v-btn
                      class="custom-button custom-button--blue"
                      depressed
                      @click="handleDownloadClick(item, item.downloadOptions[0])"
                    >
                      Download {{ item.downloadOptions[0].shortName }}
                    </v-btn>
                    <v-menu
                      v-if="item.downloadOptions.length > 1"
                      offset-y
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="custom-button custom-button--blue px-0"
                          depressed
                          style="min-width: 30px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="#fff" size="13">
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <OuterList
                        :items="item.downloadOptions"
                        @click="handleDownloadClick(item, $event)"
                      />
                    </v-menu>
                  </v-btn-toggle>
                </template>
              </td>
              <td
                v-else
                class="text-center"
              >
                <template
                  v-if="item.status === 'processing'"
                >
                  <v-row>
                    <v-col
                      cols="10"
                      style="padding: 10px 0; overflow-x: hidden;"
                    >
                      <v-col
                        cols="12"
                        style="padding: 10px 0; overflow-x: hidden;"
                      >
                        <v-progress-linear
                          color="#dee7ee"
                          :background-color="
                            item.status === 'finalizing' ? null : '#dee7ee'
                          "
                          height="10"
                          :value="item.percentage"
                          :indeterminate="item.status === 'finalizing'"
                        >
                          <div
                            v-if="item.status !== 'finalizing'"
                            class="percentage-display-box"
                            :style="
                              (Math.ceil(item.percentage) > 50
                                ? 'right: ' + (100 - item.percentage)
                                : 'left: ' + item.percentage) + '%'
                            "
                          >
                            <strong>{{ Math.ceil(item.percentage) }}%</strong>
                          </div>
                        </v-progress-linear>
                      </v-col>
                      <v-col
                        v-if="item.status !== 'finalizing' && item.estimatedTimeLeft"
                        cols="12"
                        class="text-center"
                        style="padding: 0;"
                      >
                        <small>
                          <span
                            class="mr-3"
                            style="font-size: 11px;"
                          >
                            {{ item.amountOfRowsProcessed.toLocaleString() }} / {{ item.totalRows }}
                          </span>
                          {{ item.estimatedTimeLeft }} left
                        </small>
                      </v-col>
                    </v-col>
                    <v-col
                      cols="2"
                      style="padding: 10px 0; overflow-x: hidden;"
                    >
                      <CustomCancelButton
                        class="mt-1"
                        @click="cancelDialog = true; cancelImportId = item.id;"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-else-if="item.status === 'requires action'">
                  <v-btn
                    class="custom-button custom-button--blue"
                    depressed
                    width="150px"
                    :block="$vuetify.breakpoint.xsOnly"
                    @click="onClickStatus(item)"
                  >
                    Review Import
                  </v-btn>
                  <CustomCancelButton
                    class="ml-3"
                    @click="cancelDialog = true; cancelImportId = item.id;"
                  />
                </template>
                <template v-else>
                  <CustomCancelButton
                    v-if="item.status !== 'canceled'"
                    class="ml-3"
                    @click="cancelDialog = true; cancelImportId = item.id;"
                  />
                </template>
              </td>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td
              :colspan="!forCurrentImports ? 8 : 7"
              class="text-center"
            >
              No results available.
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="cancelDialog"
      scrollable
      max-width="360px"
      @click:outside="cancelImportId = null"
    >
      <DeleteConfirmationDialog
        v-if="cancelDialog"
        header="Cancel Import"
        subheader="Do you really want to cancel this import?"
        action_button_name="Cancel"
        cancel_button_name="Close"
        @delete="cancelImport(cancelImportId)"
        @dismiss="
          cancelDialog = false;
          cancelImportId = null;
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import OuterList from "@/sharedComponents/OuterList";
import CustomCancelButton from "@/sharedComponents/CustomCancelButton";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "ImportsTable",
  components: {
    OuterList,
    CustomCancelButton,
    DeleteConfirmationDialog
  },
  mixins: [datesMixin],
  props: {
    imports: {
      default: () => [],
      type: Array,
    },
    forCurrentImports: {
      default: true,
      type: Boolean,
    },
    wantsFileName: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      failedReasonToShow: null,
      downloadOptions: {
        full: {
          label: "Full Review File",
          value: "full",
          shortName: "Review",
        },
        requiresReview: {
          label: "Rows Requiring Review",
          value: "requiresReview",
          shortName: "Review",
        },
      },
      cancelDialog: false,
      cancelImportId: null,
    };
  },
  computed: {
    formattedImports() {
      return this.imports.map(this.formatImport);
    },
  },
  methods: {
    formatImport(importItem) {
      importItem.username = this.getUserName(importItem);

      if (importItem.user) {
        importItem.email = importItem.user.email ?? null;
      }

      importItem.prettyStatus = " " + importItem.status;
      importItem.prettyStatus = this.getUpperCase(importItem.prettyStatus);
      importItem.importType = this.getUpperCase(importItem.importType);
      importItem.createdAtFullDateTime = this.getDateWithDayNameAndTime(importItem.createdAt);
      importItem.updatedAtFullDateTime = this.getDateWithDayNameAndTime(importItem.updatedAt);
      importItem.createdAt = this.getDate(importItem.createdAt);
      importItem.updatedAt = this.getDateWithTime(importItem.updatedAt);
      importItem.percentage = (importItem.amountOfRowsProcessed / importItem.totalRows) * 100;
      importItem.totalRows = importItem.totalRows.toLocaleString();
      importItem.downloadOptions = [];

      for (let key in importItem.reviewFileLinks) {
        if (this.downloadOptions[key]) {
          importItem.downloadOptions.push(this.downloadOptions[key]);
        }
      }

      importItem.downloadOptions.push({
        label: 'Original/Uploaded File',
        value: 'original',
        shortName: "Original",
      });

      return importItem;
    },
    getUserName(importItem) {
      if (!importItem.user || (Array.isArray(importItem?.user) && importItem?.user?.length < 1)) {
        return "Pulse Health - System";
      }
      return importItem.user.firstName + " " + importItem.user.lastName;
    },
    getUpperCase(str) {
      str = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
      str = str.toLowerCase().split("_").join(" ");
      return (" " + str).replace(/ (\w)/g, (a) => a.toLocaleUpperCase()).trim();
    },
    getImportReviewLink(importItem, type = "") {
      const accountId = this.$store.getters["user/account"]?.accountId;
      if (!accountId) {
        return;
      }

      let apiUrl = process.env.VUE_APP_REST_ADDRESS;
      apiUrl = apiUrl.substr(-1) === "/" ? apiUrl.slice(0, -1) : apiUrl;

      let reviewLink =
        apiUrl +
        "/imports/" +
        accountId +
        "/" +
        importItem.id +
        "/download_review_file";
      if (type) {
        reviewLink += "?type=" + type;
      }

      return reviewLink;
    },
    handleDownloadClick(importItem, evt) {
      window.open(this.getImportReviewLink(importItem, evt.value));
    },
    onClickStatus(item) {
      if (item.status !== "requires action") {
        return;
      }
      switch (item.importType) {
        case "Contact":
          this.$router.push({
            name: "ContactImportProcessView",
            params: {
              id: item.id,
            },
          });
      }
    },
    async cancelImport(importId) {
      await this.$rest.imports.put_resource(importId, {status: 'canceled'});

      this.cancelDialog = false;
      this.cancelImportId = null;
      this.$emit('canceled', importId);
    },
    getPlaceholder(item) {
      if (!item) return "-";
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
.imports-table {
  &__header {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
    }
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__text {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;

    &--blue {
      font-size: 13px !important;
      color: #2b84eb !important;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .star-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: 3px;
  }
  .align-button-to-right {
    text-align: right;
    width: 150px;
  }
  .toggle-to-right {
    margin-left: 6vw;
  }
  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 11px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
      div, span {
        font-size: 13px;
      }
    }
  }
  .percentage-display-box {
    position: absolute;
    padding: 2px 6px;
    border-radius: 2px;
    box-shadow: 0 1px 9px 0 rgba(43, 132, 235, 0.41);
    border: solid 1.5px #2b84eb;
    background-color: #fff;
    color: #2b84eb;
    transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  ::v-deep .v-progress-linear {
    // Allows percentage box to be seen
    overflow: visible;
  }
  ::v-deep .v-progress-linear__determinate {
    background-image: linear-gradient(to left, #2b84eb, #63abff);
  }
  ::v-deep .v-data-table {
    thead {
      tr {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09) !important;
      }
      tr > th {
        border-top: solid 1px #dee7ee;
        border-bottom: solid 1px #dee7ee !important;
      }
      tr > th:first-child {
        border-left: solid 1px #dee7ee;
        border-top-left-radius: 4px;
      }
      tr > th:last-child {
        border-right: solid 1px #dee7ee;
        border-top-right-radius: 4px;
      }
    }
    tbody {
      // table border radiuses
      tr:last-child > td:first-child {
        border-bottom-left-radius: 4px;
      }
      tr:last-child > td:last-child {
        border-bottom-right-radius: 4px;
      }
      // table border colors
      tr > td {
        border-bottom: solid 1px #dee7ee;
      }
      tr > td:first-child {
        border-left: solid 1px #dee7ee;
      }
      tr > td:last-child {
        border-right: solid 1px #dee7ee;
      }

      tr:hover {
        background: #f2faff !important;
      }
    }
  }
}
::v-deep .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 36px;
  opacity: 1;
}
</style>
