<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Bulk Contact Import"
      class="page-header-block"
    >
      <template #subheader>
        <div>
          Fill out the following information and import a list of contacts in a
          CSV format
        </div>
      </template>
    </PageHeader>

    <v-divider class="mb-6" />

    <v-row class="py-4">
      <v-col cols="12" sm="4" class="py-0">
        <CustomDropdown
          header="Segment"
          subheader="Which segment should these contacts be added to?"
          placeholder="Select segment"
          header-class="field-header"
          :items="available_segments"
          item-text="text"
          :value="selectedSegment"
          @input="(newValue) => (selectedSegment = newValue)"
        />
      </v-col>
    </v-row>

    <v-divider class="mt-3 mb-2" />

    <v-row class="pa-0 py-4 flex-nowrap">
      <v-col cols="12">
        <div class="import-headline py-2">
          Import File
        </div>
        <div class="import-subheadline pb-0">
          The file provided must be a CSV. To import successfully, please
          <a href="#" @click="downloadTemplate">download our template</a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
        <v-btn
          class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
          style="min-width: 150px"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="selectFile"
        >
          {{ uploadFileName ? `File: ${uploadFileName}` : "Choose File" }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-5 mb-6" />

    <v-row class="pa-0 flex-wrap">
      <v-col
        cols="12"
        class="d-flex justify-center justify-sm-end align-center flex-wrap"
      >
        <div v-if="uploadPending" class="add-contact-progress pr-sm-7">
          Processing file (5/63)
        </div>
        <div
          v-else-if="uploadError"
          class="add-contact-progress add-contact-progress--error pr-sm-7"
        >
          The supplied file was invalid
        </div>
        <v-btn
          class="custom-button my-4 px-14"
          :class="uploadPending ? 'custom-button--gray' : 'custom-button--blue'"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="onImportContacts"
        >
          Import
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="currentImports.length" class="field__header mb-4">
      Active Imports
    </div>
    <ImportsTable
      v-if="currentImports.length"
      :imports="currentImports"
    />

    <div class="field__header mt-10 mb-4">
      Previous Imports
    </div>
    <ImportsTable
      :imports="previousImports"
      :for-current-imports="false"
    />
    <PaginationButtons
      :current-page-number="importsPageNumber"
      :current-page-size="importsPageSize"
      :count-total="importsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getImportsNextPage"
      @prev-page="getImportsPrevPage"
      @change-page-size="importsChangePageSize"
    />

    <v-dialog v-model="success_dialog" scrollable max-width="315px">
      <SuccessfulImportAlert
        v-if="success_dialog"
        :number_of_contacts="number_of_imported_contacts"
        :message="success_message"
        @dismiss="success_dialog = false"
      />
    </v-dialog>

    <input
      ref="fileUpload"
      type="file"
      class="d-none"
      accept=".csv"
      @change="onFileSelected"
    >
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import SuccessfulImportAlert from "@/views/Contacts/components/AddContact/SuccessfulImportAlert";
import ImportsTable from "@/views/Imports/components/ImportsTable";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "BulkImportContact",
  components: {
    ImportsTable,
    PageHeader,
    CustomDropdown,
    SuccessfulImportAlert,
    PaginationButtons
  },
  data() {
    return {
      available_sources: [],
      available_segments: [],
      success_dialog: false,
      success_message: '',
      selectedSegment: null,
      number_of_imported_contacts: null,
      selectedSource: null,
      uploadPending: false,
      uploadError: false,
      uploadFileName: null,
      file: null,
      importedFiles: [],
      previousImports: [],
      currentImports: [],
      completedStatuses: ['completed', 'canceled', 'failed'],
      importsPageNumber: 1,
      importsPageSize: 25,
      importsTotal: 0,
    };
  },
  async created(){
    await this.getPreviousImports();
    await this.getCurrentImports();
    await this.get_available_segments();
    // await this.get_available_sources();

    this.eventSource = await this.$store.dispatch('mercure/import_type_subscribe', 'contact');
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_import_type_last_event', {
        importType: 'contact',
        lastEventId: msg.lastEventId
      });

      const parsedMessage = JSON.parse(msg.data);

      // only allowed contact type here
      if (parsedMessage.importType !== 'contact') {
        return;
      }

      const currentImportIndex = this.currentImports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentImportIndex === -1) {
        this.currentImports.unshift(parsedMessage);
        return;
      }

      // has error
      if (parsedMessage.status === 'requires action') {
        this.$store.commit('snackbar/showMessage', {
          color: 'warning',
          content: 'An import has actions need to be solved before continuing, please check.'
        });
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentImports, currentImportIndex);
        this.getPreviousImports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An import has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentImports, currentImportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    selectFile() {
      this.$refs.fileUpload.click();
    },
    /**
     * On File Selected
     * @param {{target: {files: FileList}}} event
     */
    onFileSelected(event) {
      const file = event.target.files[0];
      this.file = file;
      this.uploadFileName = file.name;
    },
    async onImportContacts() {
      if (!this.file) {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Please select a CSV file before continue.',
          'color': 'error',
        });
      }

      try {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('importType', 'contact');
        if (this.selectedSegment) {
          formData.append('importData[segmentId]', this.selectedSegment);
        }

        const { data } = await this.$rest.imports.post_resource(formData);

        this.postUploadStatusHandler(data);
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }

        return this.$store.commit('snackbar/showMessage', {
          'content': 'Error while trying to upload the file, please try again.',
          'color': 'error',
        });
      }
    },
    async get_available_sources(){
      this.available_sources = (await this.$rest.source.get_collection()).data.items.map(i => ({text: i.name, value: i.id}))
    },
    async get_available_segments(){
      this.available_segments = (await this.$rest.segment.get_collection({
        ignorePagination: true,
        sort:['name:asc'],
        isArchived: false,
      })).data.items.map(i => ({text: i.name, value: i.id}))
    },
    downloadTemplate() {
      window.location.replace(
        process.env.VUE_APP_REST_ADDRESS + `imports/${this.$store.getters["user/account"].accountId}/template/contact`
      );
    },
    /**
     * After uploaded file, handle some specific cases based on the message
     * @param data
     * @returns {*}
     */
    postUploadStatusHandler(data) {
      if (data.status === 'failed') {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Failed to process this file. Please try again.',
          'color': 'error',
        });
      }

      this.success_message = 'Your file has been added into the Queue.';
      this.success_dialog = true;

      this.file = null;
      this.uploadFileName = null;
      this.$refs.fileUpload.value = null;

      this.getCurrentImports();
    },
    async getPreviousImports() {
      const resp = await this.$rest.imports.get_collection({
        limit: this.importsPageSize,
        page: this.importsPageNumber,
        status: this.completedStatuses,
        sort: ['updatedAt:desc'],
        importType: 'contact',
      });

      this.previousImports = resp.data.items;
      this.importsTotal = resp.data.totalCount;
    },
    async getImportsNextPage() {
      this.importsPageNumber += 1;
      await this.getPreviousImports();
    },
    async getImportsPrevPage() {
      this.importsPageNumber -= 1;
      await this.getPreviousImports();
    },
    async importsChangePageSize(size) {
      this.importsPageSize = size;
      this.importsPageNumber = 1;
      await this.getPreviousImports();
    },
    async getCurrentImports() {
      const resp = await this.$rest.imports.get_collection({
        status: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
        sort: ['createdAt:desc'],
        importType: 'contact',
      });

      this.currentImports = resp.data.items;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-contact-progress {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #66788e;
  &--error {
    color: #dc2929;
  }
}

.page-header-block {
  margin-bottom: 45px;
}

::v-deep .field-header, .import-headline {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}

.import-subheadline {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}
</style>
